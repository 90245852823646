<template>
  <SigniModal :value="true" max-width="50rem" fullscreen-on-mobile persistent @close="$emit('close')">
    <template v-if="isContactEditor">
      <div class="identity-secondary-header identity-secondary-header-contact">
        {{ currentUser && currentUser.contact_id ? $t('contacts.edit') : $t('contacts.create') }}
      </div>
    </template>

    <template v-else>
      <div class="identity-secondary-header">
        {{ $t('contract.identity.header') }}
      </div>
      <div class="identity-main-header" :style="$vuetify.breakpoint.mobile ? 'line-height: 34px' : ''">
        {{
          currentUser && currentUser.is_proposer
            ? $t('contract.identity.proposer')
            : $t('contract.identity.counterparty')
        }}
      </div>
    </template>

    <template v-if="type === 'nature'">
      <form class="identity-modal-form" @submit.prevent="addUserNature()">
        <div class="identity-main-data">
          <v-text-field
            type="email"
            name="email"
            class="mx-auto"
            v-model="user_private.email"
            :label="`${$t('general.email_simple')}: *`"
            :error="$v.user_private.email.$error"
            :disabled="isCurrentUser && !isContactEditor"
            :hint="isEditation"
            :style="`max-width: 470px; ${$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 320px'}`"
            outlined
          >
            <template v-slot:append>
              <s-help :message="$t('contract.notification_will_be_send_to_this_email')"></s-help>
            </template>

            <template v-slot:message>
              <span v-if="isContactEditor" style="color: #e53935">
                {{ isEditation ? $t('contract.change_email_side_effect') : '' }}
              </span>
            </template>
          </v-text-field>

          <SPhoneNumberInput
            v-model="user_private.mobile"
            class="mx-auto"
            :class="{ 'pb-5': isKatastrEnabled }"
            :disabled="isRegisteredUser"
            :style="`max-width: 470px; ${$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 320px'}`"
          />

          <template v-if="isKatastrEnabled">
            <template v-if="isKatastrFormVisible">
              <div class="land-registry__input-wrapper text-center">
                <KatastrSearch
                  :defaultKatastralniUzemi="katuz"
                  @changeKatastralniUzemi="changeKatastralniUzemi($event)"
                />

                <v-text-field
                  type="text"
                  v-model="lv"
                  :label="`${$t('contract.prefill.lv')}:`"
                  class="mx-auto"
                  :style="`max-width: 470px; ${$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 320px'}`"
                  outlined
                ></v-text-field>

                <v-btn color="primary" :loading="isLoadingDataFromKatastr" x-large @click="loadFromKatastr">
                  {{ $t('contract.load_katastr') }}
                </v-btn>
              </div>
            </template>

            <template v-else-if="!vlastniciKatastr.length">
              <div class="land-registry__load-toggle text-center" @click="showKatastrForm">
                {{ $t('contract.prefill.load_katastr') }}
              </div>
            </template>

            <template v-else>
              <div class="land-registry__result-wrapper">
                <Select
                  :value="selectedOwner || vlastniciKatastr[0].fullname"
                  @select="(clickedOwner) => (selectedOwner = clickedOwner.name)"
                  :options="vlastniciKatastr.map((owner) => ({ name: owner.fullname }))"
                />
                <div @click="fillDataFromKatastr" class="land-registry__result-fill-button">
                  {{ $t('general.fill') }}
                </div>
                <div @click="resetKatastr" class="land-registry__result-reset-button">
                  {{ $t('general.reset_form') }}
                </div>
                <div class="modal-tip">
                  <p>{{ $t('contract.katastr_source_description', { lv }) }}</p>
                  <span class="modal-tip-icon">
                    <IcoInfo />
                  </span>
                </div>
              </div>
            </template>
          </template>

          <template v-if="isPrefillFromContactsEnabled">
            <SearchUserExternal
              ref="searchUserRef"
              v-model="contactsPrefill.query"
              :customSearch="contactsPrefill.query"
              :workspaceId="currentWorkspace.id"
              searchedField="data"
              :searchPlaceholder="$t('contract.prefill.contacts_placeholder')"
              :label="$t('contract.prefill.evc')"
              class="workspace-input"
              @onSelectResult="selectUserExternal"
              @closeAndAddUser="closeAndAddUser"
              @onInput="contactsPrefill.query = $event"
            />
          </template>
        </div>

        <div class="identity-type-radiofiled">
          <div class="modal-radio">
            <Radio
              :disabled="isRegisteredUser"
              @change="changeValue"
              :value="type"
              labelValue="nature"
              :label="$t('workspace.type.fo')"
            />
            <Radio
              :disabled="isRegisteredUser"
              @change="changeValue"
              :value="type"
              labelValue="legal"
              :label="$t('workspace.type.osvc_or_po')"
            />
          </div>
        </div>

        <Input
          v-model="user_private.firstname"
          type="text"
          class="workspace-input"
          :label="`${$t('general.name')}: *`"
          :validation="!$v.user_private.firstname.$error"
        />

        <Input
          v-if="isNotSearchableByContactLastname"
          v-model="user_private.lastname"
          type="text"
          class="workspace-input"
          :label="`${$t('general.surname')}: *`"
          :validation="!$v.user_private.lastname.$error"
          @input="selectUserExternalByLastname"
        />

        <SearchUserExternal
          v-else
          :customSearch="user_private.lastname"
          :validation="!$v.user_private.lastname.$error"
          :workspaceId="currentWorkspace.id"
          v-model="user_private.lastname"
          :searchPlaceholder="$t('contract.external_company.lastname_placeholder')"
          class="workspace-input"
          @onSelectResult="selectUserExternalByLastname"
          @closeAndAddUser="closeAndAddUser"
          @onInput="user_private.lastname = $event"
          ref="searchUserRef"
          :label="`${$t('general.surname')}: *`"
        />

        <div class="birthday-label-wrap-input">
          <SBirthDayPicker
            v-model="user_private.number"
            :locale="$i18n.locale"
            :label="$t('general.birth_date')"
            class="mx-auto mt-6 mb-8"
            style="max-width: 22rem"
          />
        </div>

        <div class="modal-tip">
          <p>{{ $t('contract.identity.help_date') }}</p>

          <span class="modal-tip-icon">
            <IcoInfo />
          </span>
        </div>

        <v-btn
          class="d-block mx-auto cursor-pointer"
          color="primary"
          min-width="15rem"
          outlined
          x-large
          @click="showAdditionalDetails = !showAdditionalDetails"
        >
          {{ additionalInfoText }}
        </v-btn>

        <v-expand-transition>
          <div v-show="showAdditionalDetails">
            <Input
              class="workspace-input"
              v-model="user_private.organization_street"
              :label="`${$t('company.street_and_number')}:`"
              type="text"
            />

            <Input
              class="workspace-input"
              v-model="user_private.organization_city"
              :label="`${$t('company.city')}:`"
              type="text"
            />

            <Input
              class="workspace-input"
              v-model="user_private.organization_zip"
              :label="`${$t('company.zip')}:`"
              type="text"
            />
          </div>
        </v-expand-transition>

        <v-row align="center" justify="center" class="pt-10" no-gutters>
          <v-col class="col-12">
            <span class="title font-weight-medium black--text">
              {{ $t('signature.footer.placeholder') }}
            </span>
          </v-col>

          <v-col class="col-12">
            <v-row align="center" justify="center" class="pt-2 pb-5" no-gutters>
              <v-col class="col-12 col-md-3">
                <span class="subtitle-2 font-weight-regular black--text">{{ $t('footer.text') }}:</span>
              </v-col>

              <v-col class="col-12 col-md-9">
                <v-textarea v-model="user_private.signatureFooter" rows="2" hide-details outlined></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="modal-buttons">
          <v-btn v-if="isContactEditor" type="submit" color="primary" :loading="loader" x-large>
            {{ currentUser && currentUser.contact_id ? $t('contacts.edit') : $t('contacts.create') }}
          </v-btn>

          <v-btn v-else type="submit" color="primary" :loading="loader" min-width="12rem" x-large>
            {{ $t('general.save') }}
          </v-btn>
        </div>
      </form>
    </template>

    <template v-if="type === 'legal'">
      <form @submit.prevent="addUserLegal()" class="identity-modal-form">
        <div class="identity-main-data">
          <v-text-field
            type="email"
            name="email"
            class="mx-auto"
            v-model="legal.email"
            :label="`${$t('general.email_simple')}: *`"
            :error="$v.legal.email.$error"
            :disabled="isCurrentUser && !isContactEditor"
            :hint="isEditation"
            :style="`max-width: 470px; ${$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 320px'}`"
            outlined
          >
            <template v-slot:append>
              <s-help :message="$t('contract.notification_will_be_send_to_this_email')"></s-help>
            </template>

            <template v-slot:message>
              <span v-if="isContactEditor" style="color: #e53935">
                {{ isEditation ? $t('contract.change_email_side_effect') : '' }}
              </span>
            </template>
          </v-text-field>

          <SPhoneNumberInput
            v-model="legal.mobile"
            class="mx-auto"
            :disabled="isRegisteredUser"
            :style="`max-width: 470px; ${$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 320px'}`"
          />
        </div>

        <div class="identity-type-radiofiled">
          <div class="modal-radio">
            <Radio
              :disabled="isRegisteredUser"
              @change="changeValue"
              :value="type"
              labelValue="nature"
              :label="`${$t('workspace.type.fo')}`"
            />
            <Radio
              :disabled="isRegisteredUser"
              @change="changeValue"
              :value="type"
              labelValue="legal"
              :label="`${$t('workspace.type.osvc_or_po')}`"
            />
          </div>
        </div>

        <div class="identity-main-data">
          <InputICO
            label=""
            :placeholder="`${$t('contract.external_company.ico_placeholder')} *`"
            :model="legal"
            @processExternalCompany="loadDataFromAresResponse"
            ref="icoRef"
            :has-error="$v.legal.organization_ico.$error"
          />
        </div>

        <Input
          :validation="!$v.legal.firstname.$error"
          class="workspace-input"
          v-model="legal.firstname"
          :label="`${$t('general.name')}: *`"
          type="text"
        />

        <Input
          v-if="isNotSearchableByContactLastname"
          v-model="legal.lastname"
          type="text"
          class="workspace-input"
          :label="`${$t('general.surname')}: *`"
          :validation="!$v.legal.lastname.$error"
          @input="selectUserExternalByLastname"
        />

        <SearchUserExternal
          v-else
          :customSearch="legal.lastname"
          :validation="!$v.legal.lastname.$error"
          :workspaceId="currentWorkspace.id"
          v-model="legal.lastname"
          :searchPlaceholder="$t('contract.external_company.lastname_placeholder')"
          class="workspace-input"
          @onSelectResult="selectUserExternalByLastname"
          @closeAndAddUser="closeAndAddUser"
          @onInput="legal.lastname = $event"
          :label="`${$t('general.surname')}: *`"
          ref="searchUserRef"
        />

        <Input
          v-if="!isCompanyNameHidden"
          :validation="!$v.legal.organization_name.$error"
          class="workspace-input"
          v-model="legal.organization_name"
          :label="`${$t('company.title')}:`"
          type="text"
        />

        <v-btn
          class="d-block mt-4 mx-auto cursor-pointer"
          min-width="15rem"
          outlined
          x-large
          @click="showAdditionalDetails = !showAdditionalDetails"
        >
          <span class="primary--text">
            {{ additionalInfoText }}
          </span>
        </v-btn>

        <v-expand-transition>
          <div v-show="showAdditionalDetails">
            <Input
              class="workspace-input"
              v-model="legal.organization_position"
              :label="`${$t('workspace.position')}:`"
              type="text"
            />

            <Input
              class="workspace-input"
              v-model="legal.organization_dic"
              :label="`${$t('company.dic')}:`"
              type="text"
            />

            <Input
              class="workspace-input"
              v-model="legal.organization_street"
              :label="`${$t('company.street_and_number')}:`"
              type="text"
            />

            <Input
              class="workspace-input"
              v-model="legal.organization_city"
              :label="`${$t('company.city')}:`"
              type="text"
            />

            <Input
              class="workspace-input"
              v-model="legal.organization_zip"
              :label="`${$t('company.zip')}:`"
              type="text"
            />
          </div>
        </v-expand-transition>

        <v-row align="center" justify="center" class="pt-10" no-gutters>
          <v-col class="col-12">
            <span class="title font-weight-medium black--text">
              {{ $t('signature.footer.placeholder') }}
            </span>
          </v-col>

          <v-col class="col-12">
            <v-row align="center" justify="center" class="pt-2 pb-5" no-gutters>
              <v-col class="col-12 col-md-3">
                <span class="subtitle-2 font-weight-regular black--text"> {{ $t('footer.text') }}: </span>
              </v-col>

              <v-col class="col-12 col-md-9">
                <v-textarea v-model="legal.signatureFooter" rows="2" hide-details outlined></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="modal-buttons">
          <v-btn v-if="isContactEditor" type="submit" color="primary" :loading="loader" x-large>
            {{ currentUser && currentUser.contact_id ? $t('contacts.edit') : $t('contacts.create') }}
          </v-btn>

          <v-btn v-else type="submit" color="primary" :loading="loader" min-width="12rem" x-large>
            {{ $t('general.save') }}
          </v-btn>
        </div>
      </form>
    </template>
  </SigniModal>
</template>

<script>
import _ from 'lodash'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { ContractService } from '@/services/ContractService.js'
import { mapGetters } from 'vuex'
import { KatastrService } from '@/services/KatastrService.js'
import FeatureFlags from '@/common/reusable/featureFlagsChecker'
import InputICO from '@/components/InputICO.vue'
import SearchUserExternal from './SearchUserExternal.vue'
import IcoInfo from '@/components/svg/IcoInfo.vue'
import KatastrSearch from './KatastrSearch.vue'
import SigniModal from '@/components/SigniModal'
import SPhoneNumberInput from '@/common/components/SPhoneNumberInput'
import SBirthDayPicker from '@/common/components/SBirthDayPicker'

export default {
  name: 'Identity',
  components: {
    SBirthDayPicker,
    SPhoneNumberInput,
    SigniModal,
    InputICO,
    SearchUserExternal,
    KatastrSearch,
    IcoInfo,
  },
  props: {
    modalConfig: {
      required: true,
      type: [Object, Boolean],
    },
    isContactEditor: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      contractId: this.modalConfig.contractId,
      currentUser: this.modalConfig.editedSignIdentity,

      loader: false,
      isLoadingInfoFromExternalDB: false,
      type: 'nature',
      isCompanyNameHidden: false,
      errorMessage: [],

      isKatastrFormVisible: false,
      selectedOwner: '',
      isLoadingDataFromKatastr: false,
      lv: '',
      katuz: '',
      vlastniciKatastr: [],

      contactsPrefill: {
        query: '',
      },

      legal: {
        email: this.modalConfig.newContactEmail || '',
        mobile: '',
        firstname: '',
        lastname: '',
        organization_name: '',
        organization_ico: '',
        organization_dic: '',
        position: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'legal',
        custom_inputs: [],
        signatureFooter: '',
      },

      user_private: {
        email: this.modalConfig.newContactEmail || '',
        mobile: '',
        firstname: '',
        lastname: '',
        number: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'nature',
        custom_inputs: [],
        signatureFooter: '',
      },

      isCurrentUser: false,
      isRegisteredUser: false,
      showAdditionalDetails: false,
    }
  },
  validations: {
    user_private: {
      email: {
        required,
        email,
      },
      firstname: {
        required,
        minLength: minLength(2),
      },
      lastname: {
        required,
        minLength: minLength(2),
      },
      number: {},
    },
    legal: {
      email: {
        required,
        email,
      },
      firstname: {
        required,
        minLength: minLength(2),
      },
      lastname: {
        required,
        minLength: minLength(2),
      },
      organization_name: {
        minLength: minLength(2),
      },
      organization_ico: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      workspaceById: 'workspaceById',
      workspaceSignatureFooterSettings: 'workspace/signatureFooterSettings',
    }),
    currentWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id)
    },
    isEditation() {
      return this.user_private.email || this.legal.email
    },
    isNotSearchableByContactLastname() {
      if (this.isContactEditor) {
        return true
      }
      // ref="searchUserRef" is duplicated, so display search only once, beware @input on lastname (hotfix selectUserExternalByLastname)
      return !this.hasUsersExternal || this.isPrefillFromContactsEnabled
    },
    hasUsersExternal() {
      if (this.isContactEditor) {
        return false
      }
      return _.get(this.currentWorkspace, 'has_users_external', false)
    },
    isKatastrEnabled() {
      if (this.isContactEditor) {
        return false
      }
      return FeatureFlags.isKatastrEnabledForContract(this.contract, this.workspaceById)
    },
    isPrefillFromContactsEnabled() {
      if (this.isContactEditor) {
        return false
      }
      return FeatureFlags.isPrefillFromContactsEnabledForContract(this.contract, this.workspaceById)
    },
    additionalInfoText() {
      const text = this.$t(`contacts.additional_info.${this.showAdditionalDetails ? 'hide' : 'show'}`)

      return text.charAt(0).toUpperCase() + text.slice(1)
    },
  },
  watch: {
    currentUser: 'assignCurrentUser',
    'user_private.firstname': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter(this.user_private, 'firstname', value)
        }
      },
    },
    'user_private.lastname': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter(this.user_private, 'lastname', value)
        }
      },
    },
    'legal.firstname': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter(this.legal, 'firstname', value)
        }
      },
    },
    'legal.lastname': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter(this.legal, 'lastname', value)
        }
      },
    },
    'legal.organization_name': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter(this.legal, 'organizationName', value)
        }
      },
    },
    'legal.organization_position': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter(this.legal, 'organizationPosition', value)
        }
      },
    },
  },
  created() {
    this.assignCurrentUser()
    this.preloadKatastr()
  },
  methods: {
    preloadKatastr() {
      this.loadFromKatastr(true)
    },
    async loadFromKatastr(isSilentMode) {
      if (!this.isKatastrEnabled) {
        return
      }
      const showError = (error) => (isSilentMode ? null : this.$notification.error(error))
      this.isLoadingDataFromKatastr = true

      if (!isSilentMode && !(this.lv && this.katuz)) {
        showError(this.$t('contract.prefill.katastr_required'))
        return
      }

      try {
        const json = await KatastrService.getContract({
          contractId: this.contractId,
          listVlastnictvi: this.lv,
          katastralniUzemi: this.katuz,
        })
        this.lv = String(json.listVlastnictvi)
        this.katuz = String(json.katastralniUzemi)
        this.vlastniciKatastr = json.vlastnici
        if (json.vlastnici.length) {
          this.isKatastrFormVisible = false
        } else {
          showError(this.$t('contract.prefill.katastr_error'))
        }
        this.isLoadingDataFromKatastr = false
      } catch (e) {
        showError(this.$t('contract.prefill.katastr_error'))
        this.isLoadingDataFromKatastr = false
      }
    },
    changeKatastralniUzemi(katuz) {
      this.katuz = katuz
    },
    resetKatastr() {
      this.showKatastrForm()
    },
    showKatastrForm() {
      this.isKatastrFormVisible = true
    },
    fillDataFromKatastr() {
      const selectedOwnerObject = this.vlastniciKatastr.find(
        (owner) => owner.fullname === (this.selectedOwner || this.vlastniciKatastr[0].fullname),
      )

      this.user_private.organization_street = selectedOwnerObject.street
      this.user_private.organization_city = selectedOwnerObject.city
      this.user_private.organization_zip = selectedOwnerObject.zip
      this.user_private.firstname = selectedOwnerObject.firstname
      this.user_private.lastname = selectedOwnerObject.lastname

      // TODO: not only FO is supported
      this.type = 'nature'
      this.$notification.success(this.$t('general.filled'))
    },
    loadDataFromAresResponse(response) {
      this.legal.organization_city = response.city
      this.legal.organization_street = response.street

      if (response.number) {
        if (response.street) {
          this.legal.organization_street += ' '
        }
        this.legal.organization_street += response.number
      } else {
        this.legal.organization_street = ''
      }

      this.legal.organization_dic = response.dic
      this.legal.organization_ico = response.ico
      this.legal.organization_zip = response.zip

      this.isCompanyNameHidden = response.type == 'citizen'
      if (this.isCompanyNameHidden) {
        this.legal.firstname = response.firstname || this.legal.firstname
        this.legal.lastname = response.lastname || this.legal.lastname
      } else {
        this.legal.organization_name = response.organizationName
      }
    },
    changeValue(newValue) {
      switch (newValue) {
        case 'legal':
          this.legal.mobile = this.user_private.mobile
          this.legal.email = this.user_private.email
          break
        case 'nature':
          this.user_private.mobile = this.legal.mobile
          this.user_private.email = this.legal.email
          break
      }

      this.type = newValue
    },
    addUserLegal() {
      this.loader = true
      this.$v.legal.$touch()
      if (this.$v.legal.$error) {
        this.loader = false
        this.errorMessage.push(this.$t('general.fill_required'))
        this.$notification.error(this.$t('general.fill_required'))
        return
      }

      if (!this.legal.organization_name || this.legal.organization_name.length === 0) {
        this.legal.type = 'citizen'
      }

      this.createUserCounterparty(this.legal)
    },
    addUserNature() {
      this.loader = true

      this.$v.user_private.$touch()

      if (this.$v.user_private.$error) {
        this.loader = false
        this.errorMessage.push(this.$t('general.fill_required'))
        this.$notification.error(this.$t('general.fill_required'))
        return
      }

      this.createUserCounterparty(this.user_private)
    },
    createUserCounterparty(user) {
      if (!this.isRegisteredUser) {
        if (this.custom_inputs) {
          user.custom_inputs = this.custom_inputs
        }
      }

      this.$emit('upsertIdentity', {
        updatedIdentity: user,
        modalConfig: this.modalConfig,
      })
    },
    assignCurrentUser() {
      if (this.currentUser?.type) {
        const signatureFooterFirstLine = [
          this.workspaceSignatureFooterSettings?.enabledName ? this.currentUser?.firstname : null,
          this.workspaceSignatureFooterSettings?.enabledLastname ? this.currentUser?.lastname : null,
        ]?.filter((item) => item)
        const signatureFooterSecondLine = [
          this.workspaceSignatureFooterSettings?.enabledOrganizationName ? this.currentUser?.organization_name : null,
          this.workspaceSignatureFooterSettings?.enabledOrganizationPosition
            ? this.currentUser?.organization_position
            : null,
        ]?.filter((item) => item)

        this.isCurrentUser = true

        if (this.currentUser.type === 'citizen') {
          this.currentUser.type = 'legal'
        }

        this.type = this.currentUser.type
        this.assignUser(
          this.type,
          Object.assign(
            {},
            {
              ...this.currentUser,
              organization_position: this.currentUser?.organization_position || '',
              signatureFooter:
                this.currentUser?.signatureFooter ||
                [signatureFooterFirstLine.join(' '), signatureFooterSecondLine.join(', ')].join('\n'),
            },
          ),
        )
      }

      this.isRegisteredUser = !!this.currentUser?.workspace_id || false
    },
    assignUser(type, user) {
      switch (type) {
        case 'nature':
          this.user_private = Object.assign({}, { ...user })
          break
        case 'citizen':
          this.type = 'legal'
          this.legal = Object.assign(
            {},
            {
              ...user,
              type: 'legal',
            },
          )
          break
        case 'legal':
          this.legal = Object.assign({}, { ...user })
          break
      }
    },
    createDefaultUserFromExternal(userExternal, type) {
      const tmpUser = Object.assign(
        {
          // default fields used before https://gitlab.com/digitalfactorycz/ismlouva/-/commit/493b995 to avoid undefined errors
          email: '',
          firstname: '',
          lastname: '',
          mobile: '',
          organization_position: null,
          identification_number: '',
          organization_name: '',
          organization_street: '',
          organization_city: '',
          organization_zip: '',
          signatureFooter: null,
        },
        this.currentUser || {}, // don't lose is_proposer, contract_role etc. if unregistered user is edited
        userExternal, // TODO: contacts - if type and ares is called after import, then user is searchable via SignatorySearch
        {
          type: type,
          position: '',
          custom_inputs: [],
        },
      )

      if ('nature' === type) {
        tmpUser.organization_ico = null
      }

      return tmpUser
    },
    async selectUserExternalByLastname(userExternal) {
      if (this.isNotSearchableByContactLastname) {
        return
      }
      return this.selectUserExternal(userExternal)
    },
    async selectUserExternal(userExternal) {
      if (!this.hasUsersExternal) {
        return
      }
      const [type, aresResponse] = await this.detectUserType(userExternal)
      this.$refs.searchUserRef.loading = false
      if (!type) {
        return
      }
      this.type = type
      const user = this.createDefaultUserFromExternal(userExternal, type)
      this.assignUser(type, user)
      if (!aresResponse) {
        return
      }
      this.$refs.searchUserRef.success = true
      this.loadDataFromAresResponse(aresResponse)
      this.addUserByCurrentType()
    },
    async detectUserType(userExternal) {
      if (!userExternal.organization_ico) {
        return ['nature', null]
      }
      return ContractService.searchAres(userExternal.organization_ico).then((response) => {
        if (response.code >= 400) {
          this.$notification.error(this.$t('contract.external_company.not_found'))
          return ['legal', null]
        }
        return ['legal', response]
      })
    },
    addUserByCurrentType() {
      switch (this.type) {
        case 'nature':
          this.addUserNature()
          break
        case 'legal':
          this.addUserLegal()
          break
      }
    },
    closeAndAddUser() {
      this.addUserByCurrentType()
      this.$emit('close')
    },
    clearForm() {
      this.user_private = ''
      this.legal = ''
    },
    updateFooter(source, item, value) {
      const footerPreview = {
        firstname: this.workspaceSignatureFooterSettings?.enabledName ? source?.firstname || '' : null,
        lastname: this.workspaceSignatureFooterSettings?.enabledLastname ? source?.lastname || '' : null,
        organizationName: this.workspaceSignatureFooterSettings?.enabledOrganizationName
          ? source?.organization_name || ''
          : null,
        organizationPosition: this.workspaceSignatureFooterSettings?.enabledOrganizationPosition
          ? source?.organization_position || ''
          : null,
      }

      if (null === footerPreview[`${item}`]) {
        return
      }

      footerPreview[`${item}`] = value

      source.signatureFooter = [
        [footerPreview?.firstname || '', footerPreview?.lastname || '']?.filter((item) => item)?.join(' '),
        [footerPreview?.organizationName || '', footerPreview?.organizationPosition || '']
          ?.filter((item) => item)
          ?.join(', '),
      ].join('\n')
    },
  },
}
</script>

<style lang="scss">
.notificationCenter.topRight {
  z-index: 10000;
}

.v-btn__content .v-btn__plain {
  opacity: 1 !important;

  &:hover {
    opacity: 0.62 !important;
  }
}

.modal-container {
  @include md() {
    padding: 60px 30px;
  }
}

.modal-tip {
  width: 100%;
  max-width: 310px;
  border-radius: 6px;
  font-size: 15px;
  text-align: left;
  line-height: 21px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  p {
    color: #9fa6b2;
    font-weight: 400;
    padding-left: 22px;
    position: relative;
    top: -5px;
  }

  .modal-tip-icon {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    top: 0;
  }

  @include md {
    padding: 10px 15px;
  }
}

.modal-line {
  width: 100%;
  max-width: 310px;
  padding-top: 25px;
  margin: 0 auto;
  border-bottom: 1px solid #cdcdcd;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.identity-type-radiofiled {
  margin: 0 -30px;
  padding: 38px;
  //border-bottom: 1px solid #E7EAEE;
}

.modal-radio {
  display: flex;
  width: fit-content;
  flex-grow: initial;
  align-items: inherit;
  margin: 0 auto;
  border: 1px solid #b1bbcb;
  border-radius: 6px;

  .radio-wrap {
    padding: 20px 17px;
    margin: 0;

    &:last-child {
      border-left: 1px solid #b1bbcb;
      @include md {
        border-left: 0;
        border-top: 1px solid #b1bbcb;
      }
    }

    .radio-label {
      display: flex;
      input {
        padding: 0;
        margin: 0;
        top: 0;
        margin-right: 10px;
      }
    }
  }
  @include md {
    display: block;
  }
}
.modal-buttons {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 40px;
}

.modal-wrapper {
  .input {
    width: 321px;
  }
}
.modal-wrapper {
  .radio-wrap {
    max-width: 321px;
  }
}

.new-user-title {
  margin-bottom: 35px;
}

.modal-inner {
  .icon-close:before {
    color: #979797;
    font-size: 16px;
  }
}

.modal-wrapper .input {
  @media screen and (max-width: 450px) {
    width: 100% !important;
  }
}

.identity-secondary-header {
  font-weight: bold;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;

  &-contact {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.identity-main-header {
  font-weight: bold;
  font-size: 32px;
  color: #000;
  line-height: 10px;
  margin-bottom: 35px;
}
.identity-main-data {
  background: #f2f6fc;
  padding: 20px;
  margin: 0 -30px;
  //border-top: 1px solid #E7EAEE;
  //border-bottom: 1px solid #E7EAEE;
  border-radius: 8px;
}
.identity-modal-form .input-label-wrap {
  position: absolute;
  left: -200px;
  width: 200px;
  padding-right: 20px;
  float: right;
  height: 56px;
  display: inline-block;
  @include md {
    left: initial;
    width: 100%;
    padding: 0;
    float: none;
    height: initial;
    position: relative;
  }
}

.identity-modal-form .input__label {
  text-align: right;
  line-height: 56px;
  @include md {
    text-align: left;
    line-height: initial;
  }
}

.identity-modal-form .input__data {
  overflow: hidden;
}

.birthday-label-wrap {
  position: absolute;
  left: -200px;
  width: 200px;
  padding-right: 20px;
  float: right;
  height: 56px;
  display: inline-block;
  @include md {
    position: relative;
    left: 0;
    width: 100%;
    padding-right: 0;
    float: left;
    height: initial;
    display: inline-block;
  }
}

.birthday-label-wrap span {
  line-height: 56px;
  text-align: right;
  width: 100%;
  display: inline-block;
  font-weight: normal;
  font-size: 15px;
  color: black;
  @include md {
    line-height: initial;
    text-align: left;
    width: 100%;
    display: inline-block;
    font-weight: normal;
    font-size: 15px;
    color: black;
  }
}
.birthday-label-wrap-input {
  max-width: 320px;
  height: 56px;
  margin: 0 auto;
  position: relative;
  @include md {
    height: unset;
  }
}
.identity-modal-form .birth-picker__items .input-label-wrap {
  display: none;
}

body .search__user .input-search {
  margin-left: auto !important;
}

.land-registry__input-wrapper {
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #a8afba6e;
  position: relative;
  button {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.land-registry__result-wrapper {
  position: relative;
  max-width: 310px;
  margin: 0 auto;
  .land-registry__result-fill-button {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-decoration: underline;
    top: 20px;
    right: -120px;
    width: 100px;
    text-align: left;
    cursor: pointer;
  }
  .land-registry__result-reset-button {
    position: absolute;
    font-size: 16px;
    color: #666;
    top: 20px;
    right: -190px;
    width: 100px;
    text-align: left;
    cursor: pointer;
  }
}
.land-registry__load-toggle {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: black;
  font-size: 16px;
}
</style>
