<template class="contact-overview">
  <div
    class="contact-overview__avatar"
    :class="[type]"
    :style="[avatarUrl ? { 'background-image': 'url(' + avatarUrl + ')' } : { 'background-image': 'unset' }]"
  >
    <span v-if="!avatarUrl">{{ initials }}</span>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    initials: String,
    type: String,
    avatarUrl: String,
  },
}
</script>

<style lang="scss">
.contact-overview {
  cursor: pointer;
  margin-top: 10px;
  @include clearfix;
}

.selected,
.contact-overview__avatar:hover {
  .contact-overview__avatar {
    /*box-shadow: 0 0 12px #6decae;*/
    box-shadow: 0 0 12px rgba(236, 159, 109, 0.5);

    // Todo: Implement user type custom color?
    /*&.legal {*/
    /*!*box-shadow: 0 0 12px rgba(94, 36, 158, 0.51);*!*/
    /*box-shadow: 0 0 12px rgba(109, 236, 176, 0.51);*/
    /*}*/

    /*&.citizen {*/
    /*box-shadow: 0 0 12px rgba(113, 215, 228, 0.51); //#598dde;*/
    /*}*/

    /*&.nature {*/
    /*!*box-shadow: 0 0 12px rgba(109, 236, 176, 0.51);*!*/
    /*box-shadow:  0 0 12px rgba(236, 159, 109, 0.5);*/
    /*}*/

    &.external {
      box-shadow: 0 0 12px rgba(241, 208, 101, 0.5);
    }
  }
}

.contact-overview__avatar {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 1;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 30px;
  background-color: #6decb0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;

  // Todo: Implement user type custom color?
  /*&.legal {*/
  /*!*background-color: #5e249e;*!*/
  /*background-color: #6decb0;*/
  /*}*/

  /*&.citizen {*/
  /*background-color: #71d7e4; //#598dde;*/
  /*}*/

  /*&.nature {*/
  /*!*background-color: #6decb0;*!*/
  /*background-color: #ec9f6d;*/
  /*}*/

  &.external {
    background-color: #f1d065;
  }
}
</style>
