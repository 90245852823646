import { apiUrl } from '@/app/config/appEnvironment'
import { authHeader } from '@/config/authHeader'
import { parseJSON } from '@/services/helpers/json.js'

export const ContactService = {
  getContacts,
  manageContacts,
  deprecatedSearchInIdentityModal,
}

function deprecatedSearchInIdentityModal(workspaceId, query, field) {
  return getContacts({
    workspace_id: workspaceId,
    search_query: query,
    search_field: field,
    pagination: {
      limit: 20,
      offset: 0,
    },
  })
}

function getContacts(query) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(query),
  }
  return fetch(`${apiUrl}/api/v1/workspaces/${query.workspace_id}/contacts`, requestOptions).then(handleResponse)
}

function manageContacts({ workspace_id, action, contacts, groups }) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader() },
    body: JSON.stringify({
      // check constants in ManageContacts and format in ManageContactsTest
      action,
      contacts: contacts || [],
      groups: groups || [],
    }),
  }
  return fetch(`${apiUrl}/api/v1/workspaces/${workspace_id}/contacts`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = parseJSON(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
