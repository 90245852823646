<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="search__user">
    <Search
      :query="searchQuery"
      :results="searchResults"
      :validation="validation"
      :loading="isLoading"
      :canShowResults="canShowResults"
      :minLength="2"
      @onChangeDebounced="fetch"
      @onSearch="search"
      @onChange="changeValue"
      @onResetQuery="resetSearchQuery"
      @onSelectResult="selectResult"
      @onFocusChange="setFocus"
      :placeholder="searchPlaceholder"
      :label="label"
      ref="search"
    >
      <template v-slot:searchResult="result">
        <div v-if="result" class="contact-preview__img">
          <Avatar
            :initials="initialsFromResult(result)"
            :type="'external'"
            :avatarUrl="result.avatar_url"
            class="proposals-photo"
          />
        </div>
        {{ result.firstname }} {{ result.lastname }} <br />
        {{ result.organization_name }}
        <div class="searched-email">
          {{ result.email }}
          <template v-if="result.organization_ico"> <br />{{ result.organization_ico }} </template>
        </div>
      </template>

      <template v-slot:notFoundDialog="{ query, closeDialog }">
        <p>{{ $t('contract.external_company.not_found1') }}</p>
        <a @click="closeDialog" href="#">{{ $t('contract.external_company.not_found2') }}</a>
      </template>

      <template v-slot:successDialog="{ result, closeDialog }" class="relative">
        <p v-if="!success">{{ $t('contract.external_company.searching') }}</p>
        <p v-if="success">{{ $t('contract.external_company.filled') }}</p>
        <br />
        <br />
        <a
          v-if="success"
          @click="
            closeDialog
            $emit('closeAndAddUser')
          "
          href="#"
        >
          {{ $t('general.save_and_close') }}
        </a>

        <div v-if="false">
          <Loader v-show="loading" class="input-search__success_dialog"></Loader>
        </div>
      </template>
    </Search>
  </div>
</template>

<script>
import _ from 'lodash'

import { ContactService } from '@/services/ContactService.js'

import Search from './Search.vue'
import Loader from '@/components/Loader.vue'
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'SearchUserExternal',
  components: {
    Search,
    Loader,
    Avatar,
  },
  props: {
    customSearch: {
      type: String,
      required: false,
    },
    validation: {
      type: Boolean,
      default: true,
    },
    searchedField: {
      type: String,
      default: 'lastname',
    },
    searchPlaceholder: String,
    customLoading: Boolean,
    label: String,
    workspaceId: {
      required: true,
    },
  },
  data() {
    return {
      searchResults: [],
      searchQuery: '',
      selectedUser: null,
      loading: false,
      focus: false,
      success: false,
    }
  },
  computed: {
    canShowResults() {
      return this.searchResults.length > 0 && this.selectedUser === null && this.focus
    },
    isLoading() {
      return this.loading || this.customLoading
    },
  },
  mounted() {
    if (this.customSearch) {
      this.searchQuery = this.customSearch
    }
  },
  methods: {
    initialsFromResult(result) {
      return (_.get(result, 'firstname', '').substr(0, 1) + _.get(result, 'lastname', '').substr(0, 1)).toUpperCase()
    },
    focusInput() {
      this.$refs.search.focusInput()
    },
    selectResult(user) {
      this.$nextTick(() => {
        this.$emit('onSelectResult', user)
        if (this.searchedField == 'lastname') {
          this.searchQuery = user.lastname
        }
        this.selectedUser = user
        this.loading = true
        this.success = false
      })
    },
    fetch(userQuery) {
      this.selectedUser = null
      this.loading = true
      ContactService.deprecatedSearchInIdentityModal(this.workspaceId, userQuery, this.searchedField)
        .then(
          (response) => {
            this.searchResults = response.data
            this.searchQuery = userQuery
            return Promise.resolve(userQuery)
          },
          () => {
            this.$emit('onInput', userQuery)
            this.$notification.error(this.$t('contract.external_company.search_failed'))
            this.loading = false
            this.success = false
          },
        )
        .then((userQuery) => {
          this.$emit('onInput', userQuery)
          this.loading = false
          this.success = true
        })
    },
    search(search) {
      if (search && search.trim() !== '') {
        this.fetch(search)
        this.focusInput()
      }
    },
    resetSearchQuery() {
      this.searchQuery = ''
      this.searchResults = []
    },
    changeValue(query) {
      if (query !== '' && query.length >= 2) {
        this.loading = true
        this.searchQuery = query
        this.$emit('onInput', query)
      }
    },
    setFocus(focus) {
      this.focus = focus
    },
  },
  watch: {
    customSearch(customSearch) {
      this.searchQuery = customSearch
    },
  },
}
</script>

<style scoped>
.input-search__success_dialog {
  position: absolute;
  bottom: 20px;
  right: 0;
  -webkit-transform: scale(0.3);
  transform: scale(0.4);
}
.proposals-photo {
  background-image: unset;
  width: 32px;
  height: 32px;
  background: #6decae;
  line-height: 32px;
  color: white;
  font-weight: bold;
  border-radius: 100%;
  font-size: 14px;
  float: left;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
</style>
